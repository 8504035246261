import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'

import './index.scss'

const Loader = ({ isLoading, isBlocking, message }) => (
  isBlocking ? (
    <div
      className="dialog--update"
      style={isLoading ? {} : { display: 'none' }}
    >
      <div className="dialog-loader--wrap">
        {message}
      </div>
    </div>
  ) : (
    <div
      className="dialog-loader--wrap"
      style={isLoading ? {} : { display: 'none' }}
    >
      {message}
    </div>
  )
)

Loader.propTypes = {
  isLoading: PropTypes.bool,
  isBlocking: PropTypes.bool,
  message: PropTypes.string,
}

Loader.defaultProps = {
  isLoading: false,
  isBlocking: false,
  message: `${Translation.updating}...`,
}

export default Loader
