import { action, thunk } from 'easy-peasy'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS_GALLERY } from '../helpers/Urls'
import {
  ALL_LAYERS_KEY,
  IMAGE_SIZES,
  IMAGE_SORTINGS,
  IMAGE_TYPES,
  PATH_WIDTH_MIN,
} from '../helpers/Constants'
import { Translation } from '../helpers/Translation'
import {
  getEndpointUrl,
  log,
} from '../helpers/Utils'

const initImageFilterCounts = {
  is_missing: 0,
  is_errors: 0,
  is_notifications: 0,
  is_comments: 0,
  is_confirmed: 0,
}

const OrderStore = {
  apiErrors: '',
  orderId: 0,

  gallery: null,

  isImagesLoading: true,

  images: null,
  imageType: IMAGE_TYPES.input,
  imageSize: IMAGE_SIZES.small,
  imageSort: IMAGE_SORTINGS.id,
  // imageFilter: IMAGE_FILTERS.all, // will be not be used for now, filtering only on FE
  isCombineExplode: null,

  imageSizeSlide: 1,

  links: {},

  imageFilters: {
    is_missing: 0,
    is_errors: 0,
    is_notifications: 0,
    is_comments: 0,
    is_confirmed: 0,
  },

  imageFilterCounts: initImageFilterCounts,

  filteredImages: null,
  filterBy: 'file',
  filterWord: '',

  imageDetails: {},
  overlayImgIndex: 0,

  updatedImages: [],
  selectedImages: [],

  imagesToBeDeleted: [],
  commentsToBeDeleted: [],
  imageToBeAllowedOverwrite: null,

  downloadUrls: {},

  explodeOrderList: null,

  layerUrls: {},
  paths: {},
  pathRange: parseInt(localStorage.getItem('pathRange'), 10) || PATH_WIDTH_MIN,
  selectedBgColor: null,
  selectedImagePaths: {},
  selectedLayer: ALL_LAYERS_KEY,
  selectedGrids: [],
  orderViewingUsers: [],
  selectedLayers: {},
  selectedProperties: [],

  drawingColor: '#DB5375', // current drawing color for comments

  addApiErrors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = payload
  }),

  clearApiErrors: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = ''
  }),

  setGallery: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.gallery = payload
  }),

  setOrderId: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderId = payload
  }),

  setImageType: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageType = payload
  }),

  setImageSize: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageSize = payload
  }),

  setImageSizeSlide: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageSizeSlide = payload
  }),

  setImageSort: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageSort = payload
  }),

  setImageFilters: action((state, payload) => {
    localStorage.setItem('imageFilters', JSON.stringify(payload))
    // eslint-disable-next-line no-param-reassign
    state.imageFilters = payload
  }),

  setImageFilterCounts: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageFilterCounts = payload
  }),

  setIsCombineExplode: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isCombineExplode = payload
  }),

  setDownloadUrls: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.downloadUrls = payload
  }),

  setIsImagesLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isImagesLoading = payload
  }),

  removeImages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.images = payload
  }),

  initImages: action((state, payload) => {
    if (payload.imageType === state.imageType) {
      if (state.images !== null) {
        const existingImages = state.images || []
        const newImages = payload.images || []

        // Check before adding new data to existing state.images array
        const updatedImages = existingImages.map((existingImage) => {
          const newImage = newImages.find((image) => image.id === existingImage.id)
          // If there is a new image with the same id and the property timestamp is different, update it.
          if (newImage && newImage.image_property_timestamp !== existingImage.image_property_timestamp) {
            return newImage // Add new image instead of old image
          }
          return existingImage // If there is no change, leave the old image as is
        })

        // Add those with new ids to the list
        const nonDuplicateNewImages = newImages.filter(
          (newImage) => !existingImages.some((existingImage) => existingImage.id === newImage.id),
        )

        // eslint-disable-next-line no-param-reassign
        state.images = [...updatedImages, ...nonDuplicateNewImages] // Add updated and new images
      } else {
        // If state.images is null, assign the incoming payload directly
        // eslint-disable-next-line no-param-reassign
        state.images = payload.images
      }

      // eslint-disable-next-line no-param-reassign
      state.updatedImages = payload?.images?.filter(
        (image) => image.image_property_timestamp !== state.images.find(
          (i) => i.id === image.id,
        )?.image_property_timestamp,
      )

      // eslint-disable-next-line no-param-reassign
      state.isImagesLoading = false
    }
  }),

  setImages: action((state, payload) => {
    const oldImages = JSON.stringify(state.images)
    if (state.images !== null && oldImages !== JSON.stringify(payload)) {
      const images = JSON.parse(oldImages)
      // eslint-disable-next-line no-param-reassign
      state.updatedImages = payload?.filter(
        (image) => image.image_property_timestamp !== images.find(
          (i) => i.id === image.id,
        )?.image_property_timestamp,
      )
    }
    // eslint-disable-next-line no-param-reassign
    state.isImagesLoading = false
    // eslint-disable-next-line no-param-reassign
    state.images = payload
  }),

  resetUpdatedImages: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.updatedImages = []
  }),

  setLinks: action((state, payload) => {
    const newPayload = {}
    Object.keys(payload.urls).forEach((key) => {
      newPayload[key] = {
        ...state.links[key],
        [payload.imageSize]: payload.urls[key],
      }
    })
    // eslint-disable-next-line no-param-reassign
    state.links = { ...state.links, ...newPayload }
  }),

  resetLinks: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.links = {}
  }),

  setFilteredImages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filteredImages = payload
  }),

  setFilterBy: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filterBy = payload
  }),

  setFilterWord: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filterWord = payload
  }),

  setImageDetails: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageDetails = { ...state.imageDetails, ...payload }
  }),

  resetImageDetails: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.imageDetails = {}
  }),

  setOverlayImgIndex: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.overlayImgIndex = payload
  }),

  setSelectedImages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedImages = payload
  }),

  filterSelectedImages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedImages = state.selectedImages.filter((imageId) => payload.find((image) => image.id === imageId))
  }),

  setImagesToBeDeleted: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imagesToBeDeleted = payload
  }),

  setCommentsToBeDeleted: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.commentsToBeDeleted = payload
  }),

  setExplodeOrderList: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.explodeOrderList = payload
  }),

  setImageToBeAllowedOverwrite: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.imageToBeAllowedOverwrite = payload
  }),

  setLayerUrls: action((state, payload) => {
    Object.keys(payload).forEach((imageId) => {
      const imageLayers = state.layerUrls[imageId]
      if (imageLayers) {
        // eslint-disable-next-line no-param-reassign
        state.layerUrls[imageId] = { ...imageLayers, ...payload[imageId] }
      } else {
        // eslint-disable-next-line no-param-reassign
        state.layerUrls = {
          ...state.layerUrls,
          ...payload,
        }
      }
    })
  }),

  setPaths: action((state, payload) => {
    Object.keys(payload).forEach((imageId) => {
      const imagePaths = state.paths[imageId]
      if (imagePaths) {
        // eslint-disable-next-line no-param-reassign
        state.paths[imageId] = { ...imagePaths, ...payload[imageId] }
      } else {
        // eslint-disable-next-line no-param-reassign
        state.paths = {
          ...state.paths,
          [imageId]: payload[imageId],
        }
      }
    })
  }),

  setPathRange: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.pathRange = payload
    localStorage.setItem('pathRange', payload)
  }),

  setSelectedImagePaths: action((state, payload) => {
    Object.keys(payload).forEach((imageId) => {
      const imagePaths = state.selectedImagePaths[imageId]
      if (imagePaths) {
        // eslint-disable-next-line no-param-reassign
        state.selectedImagePaths[imageId] = { ...imagePaths, ...payload[imageId] }
      } else {
        // eslint-disable-next-line no-param-reassign
        state.selectedImagePaths = {
          ...state.selectedImagePaths,
          ...payload,
        }
      }
    })
  }),

  setSelectedBgColor: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedBgColor = payload
  }),

  setSelectedLayer: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedLayer = payload
  }),

  setSelectedGrids: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedGrids = payload
  }),

  setOrderViewingUsers: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderViewingUsers = payload
  }),

  setAllSelectedLayers: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedLayers = payload
  }),

  setSelectedLayers: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.selectedLayers[payload.imageId] = payload.layerId
  }),

  setSelectedProperties: action((state, payload) => {
    localStorage.setItem('selectedProperties', JSON.stringify(payload))
    // eslint-disable-next-line no-param-reassign
    state.selectedProperties = payload
  }),

  setDrawingColor: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.drawingColor = payload
  }),

  resetImagesFilters: thunk((actions) => {
    actions.removeImages(null)
    actions.setFilteredImages(null)
    actions.setImageFilterCounts(initImageFilterCounts)
    actions.setFilterWord('')
  }),

  getGallery: thunk(async (actions, payload) => {
    try {
      actions.setIsImagesLoading(true)
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.gallery)
        .replace('{id}', payload.orderId)

      const response = await axios.get(url, {
        params: {
          image_type: payload.imageType,
          ...(payload?.isCombineExplode && { is_combine_explode: payload.isCombineExplode }),
        },
      })

      if (response.data.result.success) {
        actions.setGallery(response.data.result)

        actions.setIsImagesLoading(false)
        return response.data.result
      }

      actions.setIsImagesLoading(false)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }

      actions.setIsImagesLoading(false)
      return false
    }
  }),

  getImages: thunk(async (actions, payload) => {
    try {
      actions.setIsImagesLoading(true)
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.galleryImages)
        .replace('{id}', payload.orderId)

      if (payload.page) log(url, 'page', payload.page, 'limit', payload.limit, (new Date()).toLocaleTimeString('en-GB'))
      else log(url, (new Date()).toLocaleTimeString('en-GB'))

      const response = await axios.get(url, {
        params: {
          image_type: payload.imageType,
          image_size: payload.imageSize,
          image_sort: payload.imageSort,
          // image_filter: payload.imageFilter, // will be not be used for now, filtering only on FE
          ...(payload?.isCombineExplode && { is_combine_explode: payload.isCombineExplode }),
          ...(payload?.nameSearch && { name_search: payload.nameSearch }),
          ...(payload?.page && { page: payload.page }),
          ...(payload?.limit && { limit: payload.limit }),
        },
      })

      if (response.data.result.success) {
        actions.filterSelectedImages(response.data.result.images)

        if (payload.page || payload.limit) {
          actions.initImages({
            images: response.data.result.images,
            imageType: payload.imageType,
          })
        } else {
          actions.setImages(response.data.result.images)
        }

        // Calculate imageFilterCounts
        const imageFilterCounts = response.data.result.images.reduce((acc, image) => {
          Object.keys(image.image_filter).forEach((key) => {
            acc[key] = (acc[key] || 0) + image.image_filter[key]
          })
          return acc
        }, {})

        actions.setImageFilterCounts(imageFilterCounts)

        actions.setIsImagesLoading(false)
        return true
      }

      actions.setIsImagesLoading(false)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }

      actions.setIsImagesLoading(false)
      return false
    }
  }),

  getLinks: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS_GALLERY.links, payload)

      if (response.data.result.success) {
        actions.setLinks({ urls: response.data.result.urls, imageSize: payload.image_sizes[0] })
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getImageDetails: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS_GALLERY.image, payload)

      if (response.data.result.success) {
        actions.setImageDetails(response.data.result.images)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  imageAction: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(payload.url, payload.body)

      if (response.data.result.success) {
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  removeError: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.errors)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  addComment: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.commentNew)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  changeReference: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.reference)
      const response = await axios.post(url, payload.body)

      if (response.data.result.success) {
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getExplodeOrderList: thunk(async (actions, payload) => {
    try {
      const url = `${getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.explode)}/${payload.orderId}`
      const response = await axios.get(url)

      if (response.data.result.success) {
        const explodeOrderList = {}
        response.data.result.explode_order_list.forEach((item) => {
          explodeOrderList[item.to_order_id] = item.label
        })
        actions.setExplodeOrderList(explodeOrderList)
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  explodeOrder: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.explode)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  updatePrices: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.prices)

      let response
      if (payload.method === 'delete') response = await axios.delete(url, { data: payload.body })
      else response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  setTestImage: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.test)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  restoreImages: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.restore)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  clearImages: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.clear)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getLayer: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS_GALLERY.layer, payload.body)

      if (response.data.result.success) {
        actions.setLayerUrls(response.data.result.urls)
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getPath: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS_GALLERY.path, payload)

      if (response.data.result.success) {
        actions.setPaths(response.data.result.svgs)
        return response.data.result
      }

      return false
    } catch (error) {
      return false
    }
  }),

  ping: thunk(async (actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.ping)
      // log(url, (new Date()).toLocaleTimeString('en-GB'))

      const response = await axios.post(url, payload.body)

      if (response.data.result.success) {
        actions.setOrderViewingUsers(response.data.result.order_viewing_users)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  setOrderRedo: thunk(async (_actions, payload) => {
    try {
      const url = getEndpointUrl(payload.is_admin, API_ENDPOINTS_GALLERY.redo)
      const response = await axios.post(url, payload.body)

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),
}

export default OrderStore
