import { action } from 'easy-peasy'

import { CONTROLS_DEFAULT_STATE, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '../helpers/Constants'
import { log, windowWidth } from '../helpers/Utils'

const LayoutStore = {
  isMobile: windowWidth() <= MOBILE_BREAKPOINT,
  isTablet: windowWidth() <= TABLET_BREAKPOINT,
  isInfosOpened: false,
  isOverlayOpened: false,
  isDraggableOpened: false,
  draggableIndex: null,
  isDialogModalOpened: false,
  dialogModalState: 0,
  isDeleteErrorsModalOpened: false,
  isExplodeOrderModalOpened: false,
  isUpdatePricesModalOpened: false,
  isShortcutsOpened: false,
  isRedoModalOpened: false,
  pathsColors: JSON.parse(localStorage.getItem('pathsColors')) || {},

  controlsState: CONTROLS_DEFAULT_STATE,
  leftPanelWidth: 360, // must correspond with CSS
  rightPanelWidth: 380, // must correspond with CSS

  updateBreakpoints: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.isMobile = windowWidth() <= MOBILE_BREAKPOINT
    // eslint-disable-next-line no-param-reassign
    state.isTablet = windowWidth() > MOBILE_BREAKPOINT && windowWidth() <= TABLET_BREAKPOINT

    log('windowWidth', windowWidth())
  }),

  updateHorizontalScroll: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isHorizontalScroll = payload
  }),

  updateInfosOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isInfosOpened = payload
  }),

  updateOverlayOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isOverlayOpened = payload
  }),

  updateControlsState: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.controlsState = payload
  }),

  updateDraggableOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isDraggableOpened = payload
  }),

  updateDraggableIndex: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.draggableIndex = payload
  }),

  updateDialogModalOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isDialogModalOpened = payload
  }),

  updateDialogModalState: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.dialogModalState = payload
  }),

  updateDeleteErrorsModalOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isDeleteErrorsModalOpened = payload
  }),

  updateExplodeOrderModalOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isExplodeOrderModalOpened = payload
  }),

  updateUpdatePricesModalOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isUpdatePricesModalOpened = payload
  }),

  updateShortcutsOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isShortcutsOpened = payload
  }),

  updateRedoModalOpened: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isRedoModalOpened = payload
  }),

  updatePathsColors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.pathsColors = payload
    localStorage.setItem('pathsColors', JSON.stringify(payload))
  }),
}

export default LayoutStore
