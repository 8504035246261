import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../../helpers/Translation'
import { isAltKey, removeClass } from '../../../../helpers/Utils'
import {
  IMAGE_ERROR_CLASS,
  IMAGE_ERROR_CODES,
  INFO_ERROR_CLASS,
} from '../../../../helpers/Constants'

import Typography from '../../../../components/Typography'

import { ReactComponent as ExpandSvg } from '../../../../svg/expand.svg'

import './index.scss'

const CollapsibleImageInfo = ({
  image,
  refreshGallery,
  resetInterval,
  isOriginalImage,
  originalImageDetails,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderActions = useStoreActions((actions) => ({
    removeError: actions.order.removeError,
  }))

  const [isCollapsed, setIsCollapsed] = useState(JSON.parse(localStorage.getItem('isImageInfoCollapsed')) || false)

  useEffect(() => {
    localStorage.setItem('isImageInfoCollapsed', isCollapsed)
  }, [isCollapsed])

  const removeErrorCode = async (e, errorCode) => {
    if (!userState.user.is_admin) return

    if (isAltKey(e)) {
      e.stopPropagation()

      if (errorCode === IMAGE_ERROR_CODES.wrongFileName) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (!image.wrong_format) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else if (errorCode === IMAGE_ERROR_CODES.wrongFormat) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (!image.wrong_file_name) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else if (errorCode === IMAGE_ERROR_CODES.colorSpaceError || errorCode === IMAGE_ERROR_CODES.noBgColor) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
      } else if (errorCode === IMAGE_ERROR_CODES.widthError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !image.height_error
          && !image.dpi_error
        ) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else if (errorCode === IMAGE_ERROR_CODES.heightError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !image.width_error
          && !image.dpi_error
        ) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else if (errorCode === IMAGE_ERROR_CODES.dpiError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !image.width_error
          && !image.height_error
        ) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
      }

      const res = await orderActions.removeError({
        is_admin: userState.user.is_admin,
        body: {
          image_ids: [image.id],
          error_codes: [errorCode],
        },
      })
      if (res) {
        refreshGallery()
        resetInterval()
      }
    }
  }

  return (
    <div className="collapsible-image-info">
      {isCollapsed && (
        <div className="collapsible-image-info__content">
          <div className="image-overlay--left__content">
            <Typography
              label={Translation.system_check}
              font="semibold"
            />

            <Typography
              label={
                `${image.original_image_id
                  ? image.original_image_id
                  : image.id}`
              }
            />

            <div
              className={
                (
                  isOriginalImage
                    ? (originalImageDetails?.wrong_file_name || originalImageDetails?.wrong_format)
                    : (image.wrong_file_name
                      || image.wrong_format)
                )
                  ? 'info info-error'
                  : 'info'
              }
            >
              <span
                className={
                  (isOriginalImage
                    ? originalImageDetails?.wrong_file_name
                    : image.wrong_file_name)
                    ? IMAGE_ERROR_CLASS
                    : ''
                }
                onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.wrongFileName)}
              >
                {
                  isOriginalImage
                    ? originalImageDetails?.name_without_format
                    : image.name_without_format
                }
              </span>
              <span>&nbsp;.&nbsp;</span>
              <span
                className={
                  (isOriginalImage
                    ? originalImageDetails?.wrong_format
                    : image.wrong_format)
                    ? IMAGE_ERROR_CLASS
                    : ''
                }
                onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.wrongFormat)}
              >
                {
                  isOriginalImage
                    ? originalImageDetails?.format
                    : image.format
                }
              </span>
            </div>

            <div
              className={
                (
                  isOriginalImage
                    ? (originalImageDetails?.width_error
                      || originalImageDetails?.height_error
                      || originalImageDetails?.dpi_error)
                    : (image.width_error
                      || image.height_error
                      || image.dpi_error)
                )
                  ? 'info info-error'
                  : 'info'
              }
            >
              <span
                className={
                  (isOriginalImage
                    ? originalImageDetails?.width_error
                    : image.width_error)
                    ? IMAGE_ERROR_CLASS
                    : ''
                }
                onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.widthError)}
              >
                {
                  isOriginalImage
                    ? originalImageDetails?.width
                    : image.width
                }
              </span>
              <span>&nbsp;x&nbsp;</span>
              <span
                className={
                  (isOriginalImage
                    ? originalImageDetails?.height_error
                    : image.height_error
                  )
                    ? IMAGE_ERROR_CLASS
                    : ''
                }
                onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.heightError)}
              >
                {isOriginalImage
                  ? originalImageDetails?.height
                  : image.height}
              </span>
              <span>
                &nbsp;
                {Translation.px}
                &nbsp;
                /
                &nbsp;
              </span>
              <span
                className={
                  (isOriginalImage
                    ? originalImageDetails?.dpi_error
                    : image.dpi_error)
                    ? IMAGE_ERROR_CLASS
                    : ''
                }
                onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.dpiError)}
              >
                {
                  isOriginalImage
                    ? originalImageDetails?.dpi
                    : image.dpi
                }
              </span>
              <span>
                &nbsp;
                {Translation.dpi}
                &nbsp;
                /
                &nbsp;
                {
                  isOriginalImage
                    ? originalImageDetails?.size_kb
                    : image.size_kb
                }
                &nbsp;
                {Translation.kb}
              </span>
            </div>

            <div
              className={
                (
                  isOriginalImage
                    ? originalImageDetails?.color_space_error
                    : image.color_space_error
                )
                  ? 'info info-error'
                  : 'info'
              }
            >
              <span
                className={
                  (
                    isOriginalImage
                      ? originalImageDetails?.color_space_error
                      : image.color_space_error
                  )
                    ? IMAGE_ERROR_CLASS
                    : ''
                }
                onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.colorSpaceError)}
              >
                {
                  isOriginalImage
                    ? originalImageDetails?.colour_space
                    : image.colour_space
                }
              </span>
            </div>

            <div
              className={
                (
                  isOriginalImage
                    ? originalImageDetails?.no_bg_color
                    : image.no_bg_color
                )
                  ? 'info info-error'
                  : 'info'
              }
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {(isOriginalImage
                ? originalImageDetails?.no_bg_color
                : image.no_bg_color)
                ? (
                  <span
                    className={
                      (isOriginalImage
                        ? originalImageDetails?.no_bg_color
                        : image.no_bg_color
                      )
                        ? IMAGE_ERROR_CLASS
                        : ''
                    }
                    onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.noBgColor)}
                  >
                    {
                      (
                        isOriginalImage
                          ? originalImageDetails?.background_color
                          : image.background_color
                      )
                        ? `
                          ${Translation.detected_background}: ${(isOriginalImage
                          ? originalImageDetails?.background_color
                          : image.background_color
                        )}
                          `
                        : Translation.background_colour_not_detected
                    }
                  </span>
                ) : (
                  isOriginalImage
                    ? originalImageDetails?.background_color
                    : image.background_color
                )
                  ? `
                    ${Translation.detected_background}: ${(isOriginalImage
                    ? originalImageDetails?.background_color
                    : image.background_color
                  )}
                    `
                  : Translation.background_colour_not_detected}
            </div>

            <Typography
              label={
                `${Translation.colour_depth}: 
                  ${isOriginalImage
                  ? originalImageDetails?.colour_depth
                  : image.colour_depth}
                  ${Translation.bits}
                  `
              }
            />
          </div>
        </div>
      )}
      <div className="collapse-toggle" onClick={() => setIsCollapsed((prev) => !prev)}>
        <ExpandSvg style={{ rotate: isCollapsed ? 'none' : '180deg' }} />
      </div>
    </div>
  )
}

CollapsibleImageInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object,
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
  isOriginalImage: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  originalImageDetails: PropTypes.object,
}

CollapsibleImageInfo.defaultProps = {
  image: {},
  refreshGallery: () => { },
  resetInterval: () => { },
  isOriginalImage: false,
  originalImageDetails: {},
}

export default CollapsibleImageInfo
