import React from 'react'
import PropTypes from 'prop-types'

import { ALL_LAYERS_KEY } from '../../helpers/Constants'

import { ReactComponent as LayerRadioButtonIconUnselectedSvg } from '../../svg/layer_radio_button_unselected.svg'
import { ReactComponent as LayerRadioButtonIconSelectedSvg } from '../../svg/layer_radio_button_selected.svg'

import './index.scss'

const RadioGroup = ({
  value,
  values,
  onChange,
}) => (
  <div className="radio-group-container">
    {
      Object.keys(values).map((key) => (
        <div key={key} className="radio-container" style={{ order: key.includes(ALL_LAYERS_KEY) ? 0 : 1 }}>
          <input
            type="radio"
            className="radio-hidden"
            id={key}
            value={key}
            checked={value === key}
            onChange={onChange}
          />
          <label htmlFor={key} className={value === key ? 'selected' : 'unselected'}>
            {value === key ? <LayerRadioButtonIconSelectedSvg /> : <LayerRadioButtonIconUnselectedSvg />}
          </label>
          <label htmlFor={key}>{values[key]}</label>
        </div>
      ))
    }
  </div>
)

RadioGroup.propTypes = {
  value: PropTypes.string,
  values: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.node)]),
  onChange: PropTypes.func,
}

RadioGroup.defaultProps = {
  value: '',
  values: [],
  onChange: () => { },
}

export default RadioGroup
