import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { DISABLED_CLASS } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'

import Tooltip from '../../components/Tooltip'

import OrderId from './OrderId'
import ImageTypes from './ImageTypes'
import Stats from './Stats'
import Avatars from './Avatars'
import Controls from './Controls'
import Filters from './Filters'
import ImageSizeRange from './ImageSizeRange'

import { ReactComponent as InfoIconSvg } from '../../svg/info_icon.svg'
import { ReactComponent as RedoIconSvg } from '../../svg/redo_icon.svg'
import { ReactComponent as BackIconSvg } from '../../svg/back_icon.svg'

import './index.scss'

const Header = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isInfosOpened: state.layout.isInfosOpened,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateInfosOpened: actions.layout.updateInfosOpened,
    updateRedoModalOpened: actions.layout.updateRedoModalOpened,
  }))

  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
    imageType: state.order.imageType,
  }))

  const handleInfoClick = () => {
    layoutActions.updateInfosOpened(!layoutState.isInfosOpened)
  }

  const onRedoClick = () => {
    if (!orderState.gallery?.is_send_into_redo) return

    layoutActions.updateRedoModalOpened(true)
  }

  return (
    <div className="header">
      <div className="header--middle__wrap--left">
        <Tooltip
          position="bottom"
          text={Translation.back}
        >
          <div className="header--wraps header--back__wrap">
            <BackIconSvg />
          </div>
        </Tooltip>

        {userState.user.is_admin ? (
          <Tooltip
            position="bottom"
            text={Translation.general_order_info}
          >
            <div
              className={`header--wraps header--image-info__wrap ${layoutState.isInfosOpened
                ? 'header--image-info__wrap--active' : ''}`}
              onClick={handleInfoClick}
            >
              <InfoIconSvg />
            </div>
          </Tooltip>
        ) : (
          <div className="header--wraps header--image-info__wrap">
            &nbsp;
          </div>
        )}

        <Tooltip
          position="bottom"
          text={Translation.send_into_redo}
        >
          <div
            className={
              `header--wraps header--send-redo__wrap 
                ${orderState.gallery?.is_send_into_redo ? '' : DISABLED_CLASS}`
            }
            onClick={onRedoClick}
          >
            <RedoIconSvg />
          </div>
        </Tooltip>

        <OrderId />

        <ImageTypes />

        <Stats />
      </div>

      <div className="header--middle__wrap--middle" />

      <div className="header--middle__wrap--right">
        <div className="header--image-size__wrap">
          <ImageSizeRange />
        </div>

        <Avatars />

        <Controls />

        <Filters />
      </div>
    </div>
  )
}

export default Header
