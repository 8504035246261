import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../../helpers/Translation'
import { PX_TO_REM } from '../../../../helpers/Constants'

import ColorPicker from '../../../../components/ColorPicker'

import CommentOverlay from '../CommentOverlay'

import { ReactComponent as EnterIconSvg } from '../../../../svg/enter.svg'
import { ReactComponent as CommentDrawingOvalSvg } from '../../../../svg/comment_drawing_oval.svg'
import { ReactComponent as CommentDrawingRectangleSvg } from '../../../../svg/comment_drawing_rectangle.svg'
import { ReactComponent as CommentDrawingArrowSvg } from '../../../../svg/comment_drawing_arrow.svg'
import { ReactComponent as CommentDrawingFreehandSvg } from '../../../../svg/comment_drawing_freehand.svg'
import { ReactComponent as CommentDrawingLineSvg } from '../../../../svg/comment_drawing_line.svg'
import { ReactComponent as CommentRefreshSvg } from '../../../../svg/comment_refresh.svg'
import { ReactComponent as ExpandSvg } from '../../../../svg/expand.svg'

import './index.scss'

const CollapsibleCommentOverlay = ({ image, refreshGallery }) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    drawingColor: state.order.drawingColor,
  }))

  const orderActions = useStoreActions((actions) => ({
    addComment: actions.order.addComment,
    setDrawingColor: actions.order.setDrawingColor,
  }))

  const [comment, setComment] = useState('')
  const [isCollapsed, setIsCollapsed] = useState(JSON.parse(localStorage.getItem('isCommentBlockCollapsed')) || false)
  const [openColorPickerComment, setOpenColorPickerComment] = useState(false)

  useEffect(() => {
    localStorage.setItem('isCommentBlockCollapsed', isCollapsed)
  }, [isCollapsed])

  const handleAddComment = async () => {
    if (comment) {
      const res = await orderActions.addComment({
        is_admin: userState.user.is_admin,
        body: {
          image_ids: [image?.id],
          comment,
          is_reply: false,
        },
      })

      if (res) {
        setComment('')
        refreshGallery()
      }
    }
  }

  const onCommentSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddComment().then(() => { })
    }
  }

  const closePicker = () => {
    setOpenColorPickerComment(false)
  }

  const handleCommentColorSelect = (color) => {
    orderActions.setDrawingColor(color)
    setOpenColorPickerComment(false)
  }

  return (
    <div
      className="collapsible-comment"
      style={{
        right: isCollapsed ? PX_TO_REM['12'] : 0,
        borderTopRightRadius: isCollapsed ? PX_TO_REM['5'] : 0,
      }}
    >
      <div className="collapse-toggle" onClick={() => setIsCollapsed((prev) => !prev)}>
        <ExpandSvg style={{ rotate: isCollapsed ? '180deg' : 'none' }} />
      </div>
      {isCollapsed && (
        <div className="collapsible-comment__content">
          <div className="collapsible-comment__active-comments">
            <div className="collapsible-comment__active-comments-title">
              {Translation.active_comments}
            </div>

            {image?.image_comments.map((imageComment) => (
              <CommentOverlay key={imageComment.id} comment={imageComment} />
            ))}
          </div>
          <div className="comment-section">
            <textarea
              placeholder={`${Translation.leave_your_comment}...`}
              className="comment-textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={onCommentSubmit}
              {...(comment && { style: { textIndent: 0 } })}
            />

            <div className="comment-section__bottom">
              <div className="comment-section__styles">
                <div className="comment-section__color" style={{ display: 'flex' }}>
                  {openColorPickerComment && (
                    <ColorPicker onColorChange={handleCommentColorSelect} closePicker={closePicker} />
                  )}
                  <button
                    type="button"
                    label="style"
                    className="comment-section__style-button"
                    style={{ backgroundColor: orderState.drawingColor }}
                    onClick={() => setOpenColorPickerComment(true)}
                  />
                </div>
                <button type="button" label="style" className="comment-section__style-button">
                  <CommentDrawingOvalSvg />
                </button>
                <button type="button" label="style" className="comment-section__style-button">
                  <CommentDrawingRectangleSvg />
                </button>
                <button type="button" label="style" className="comment-section__style-button">
                  <CommentDrawingArrowSvg />
                </button>
                <button type="button" label="style" className="comment-section__style-button">
                  <CommentDrawingFreehandSvg />
                </button>
                <button type="button" label="style" className="comment-section__style-button">
                  <CommentDrawingLineSvg />
                </button>
                <button type="button" label="style" className="comment-section__style-button gray">
                  <CommentRefreshSvg />
                </button>
              </div>
              <button
                className="comment-section__button"
                type="button"
                onClick={handleAddComment}
              >
                {Translation.add}
                <EnterIconSvg />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

CollapsibleCommentOverlay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object,
  refreshGallery: PropTypes.func,
}

CollapsibleCommentOverlay.defaultProps = {
  image: {},
  refreshGallery: () => { },
}

export default CollapsibleCommentOverlay
